import React from 'react';

import Page from '~layout/Page';

import { UnemployedHero, UnemployedFaq } from '~modules/unemployed';

const UnemployedPage = () => (
  <Page>
    <UnemployedHero />

    <UnemployedFaq />
  </Page>
);

export default UnemployedPage;
