import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > .MuiExpansionPanel-root::before': {
      height: '0px'
    },
    marginBottom: '100px'
  },

  sectionHeading: {
    fontWeight: 'bold',
    fontSize: '56px',
    lineHeight: '66px',
    marginTop: '80px',
    marginBottom: '30px'
  },
  ulStyle: {
    paddingInlineStart: `${theme.spacing(2)}px`,
    paddingTop: theme.spacing(2)
  },
  liStyle: {
    paddingBottom: theme.spacing(3),
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#000',
    'list-style-type': 'disc'
  },

  heading: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#458FCE'
  },
  paragraph: {
    fontSize: '20px',
    lineHeight: '30px'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0  auto'
  },
  expansionPanel: {
    display: 'block',
    padding: '8px 16px 0px'
  },
  expansionSummarySpacing: {
    margin: '15px 0'
  },
  expansionPanelStyle: {
    boxShadow: 'unset',
    border: 'unset',
    background: '#F7F4EF'
  }
}));

const UnemployedFaq = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container center background="#F7F4EF">
      <Typography variant="h2" className={classes.sectionHeading}>
        Frequently Asked Questions
      </Typography>

      <div className={classes.root}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          className={classes.expansionPanelStyle}
        >
          <AccordionSummary
            aria-controls="panel1"
            id="panel1"
            className={classes.expansionSummarySpacing}
          >
            <Typography className={classes.heading}>
              What’s included in an Aleph Beta Basic Membership ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionPanel}>
            <Typography className={classes.paragraph}>
              Your basic membership includes the following:
            </Typography>
            <ul className={classes.ulStyle}>
              <li className={classes.liStyle}>
                Unlimited access to over 1,000 Torah videos on the weekly Parsha, Jewish holidays,
                and more
              </li>
              <li className={classes.liStyle}>Printable transcripts to all videos</li>

              <li className={classes.liStyle}>Access to the iOS and Android app</li>

              <li className={classes.liStyle}>Download videos to your mobile device</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          className={classes.expansionPanelStyle}
        >
          <AccordionSummary
            aria-controls="panel2"
            id="panel2"
            className={classes.expansionSummarySpacing}
          >
            <Typography className={classes.heading}>How does this promotion work?</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionPanel}>
            <Typography className={classes.paragraph}>
              This offer is being made to anyone unemployed or furloughed, and living in the US. If
              this describes you, please use the above form to sign up for a subscription to Aleph
              Beta. Your subscription will be valid for six months from the day you sign up.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          className={classes.expansionPanelStyle}
        >
          <AccordionSummary
            aria-controls="panel3"
            id="panel3"
            className={classes.expansionSummarySpacing}
          >
            <Typography className={classes.heading}>
              Can I add this to my existing subscription?
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionPanel}>
            <Typography className={classes.paragraph}>
              This offer is only available for new or existing free members who are currently
              unemployed or furloughed. Any member who is unable to pay for their subscription for
              any reason can contact us at{' '}
              <Link to="mailto:info@alephbeta.org">info@alephbeta.org</Link> and our team will see
              how we can help.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
};

export default UnemployedFaq;
