import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #e2e2e1',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #b5b5b5i'
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  focused: {}
}));

const InputField = ({ ...rest }) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      required
      margin="normal"
      variant="filled"
      InputProps={{ classes, disableUnderline: true }}
      {...rest}
    />
  );
};

export default InputField;
