import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import ScrollImage from './images/Scroll.png';

import UnempoyedForm from '../UnempoyedForm';

const useStyles = makeStyles({
  media: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto'
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: '64px',
    marginBottom: '38px'
  },
  gridSpacing: {
    marginTop: '80px'
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '34px',
    marginBottom: '80px'
  }
});
const UnemployedHero = () => {
  const classes = useStyles();
  return (
    <Container background="#A2A0FF">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classes.gridSpacing}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h2" align="center" className={classes.heading}>
            Free Aleph Beta membership for the unemployed
          </Typography>
          <Typography variant="subtitle1" align="center" className={classes.subtitle}>
            In the wake of COVID-19, unemployment in the US has risen to alarming levels. To help
            those affected, we’re offering six months of Aleph Beta Basic Membership for free.*
            Discover the wisdom and guidance hidden in the Torah text, and may it bring you
            inspiration, strength and comfort through this time.
          </Typography>
          <img src={ScrollImage} alt="Scroll" className={classes.media} />
        </Grid>
        <Grid item xs={12} md={5}>
          <UnempoyedForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default UnemployedHero;
